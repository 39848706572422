import React, { useCallback, useEffect, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { Tooltip } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveProject,
  getProject,
  getWidgetAreaDisplayState,
  RootReducerState,
  getAllProjects,
  getActiveProjectInMessageCenter,
  getLastOpenDriveItemFolder,
  getWidgetAreaActiveProjectId,
  getCommentsByConversationId,
  getMessage,
  getIsFetchingComments,
  getBuddyIconVisibility,
  getIsKeyEnabled,
} from '../../../apps/main/rootReducer';
import {
  closeWidgetArea,
  openWidgetArea,
  selectWidget,
  setActiveProjectWidgetBar,
} from '../actions';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Widget } from '../reducers';
import classNames from 'classnames';
import DocumentsWidget from '../../documents/components/Widget/DocumentsWidget';
import WidgetMailSelectionList from '../../mail/components/WidgetMailSelectionList';
import TasksWidget from '../../tasks/components/TasksWidget';
import { DriveItemId, ProjectId } from '../../../models/Types';
import { createSelector } from 'reselect';
import { Project } from '../../../models/Project';
import { ResizeCallback } from 're-resizable';
import WidgetAreaResizable from './WidgetAreaResizable';
import WidgetBarUploadManagerNavigationButton from '../../documents/components/Widget/WidgetBarUploadManagerNavigationButton';
import UploadManager from '../../documents/components/Widget/UploadManager';
import { updateDriveItemPath } from '../../documents/actions/lastOpenDriveItemFolder';
import { useLocation } from 'react-router-dom';
import MailComments from './MailComment';
import { MailComment, Message } from '../../../models/Message';
import { fetchConversationComments } from '../../mail/actions/projects/mailCommentActions';
import MailTaskList from './MailTaskList';
import PrioBuddy from './PrioBuddy';
import useProjectsContext from '../../projects/hooks/useProjectsContext';

const panelDefaultWidth = 450;
const panelMaxWidth = document.body.clientWidth - 380 - 320;
const iconBarWidth = 48;

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    borderLeft: theme.old.borders.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    zIndex: 500,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  hiddenElement: {
    height: '100%',
  },
  panelChild: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    borderLeft: theme.old.borders.content,
    position: 'relative',
    flexShrink: 0,
  },
  iconBar: {
    display: 'flex',
    flexDirection: 'column',
    width: iconBarWidth,
    flexShrink: 0,
    alignItems: 'center',
  },
  toggleButton: {
    background: 'transparent',
    marginBottom: theme.old.spacing.unit(1),
  },
  iconButtonActive: {
    backgroundColor: theme.colors.application.background.selected,
  },
  documents: {
    height: '100%',
    width: '100%',
    '& .ant-upload': {
      height: '100%',
      width: '100%',
    },
  },
  iconButton: {
    transition: 'font-size 0.25s ease',
  },
  yellowDot: {
    position: 'absolute',
    top: '5px',
    right: '4px',
    width: '8px',
    height: '8px',
    backgroundColor: '#E2B203',
    borderRadius: '999px',
    padding: '4px',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#E0E6EE',
    margin: '10px 0',
  },
}));

const isMyProjectSelector = (projectId: ProjectId) =>
  createSelector<[(state: RootReducerState) => Project[]], boolean>(
    getAllProjects,
    (projects) =>
      !projectId ||
      projectId === 'me' ||
      (projects ?? []).map((project) => project.projectId).includes(projectId)
  );

interface WidgetAreaProps {
  disablePersonalMessages?: boolean;
  selectedProjectId?: ProjectId;
}

export const WidgetArea: React.FC<WidgetAreaProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { disablePersonalMessages, selectedProjectId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getProjectById } = useProjectsContext();
  const { open, selectedWidget } = useSelector(getWidgetAreaDisplayState);
  const activeProjectIdInMainScreen = useSelector(getActiveProject);
  const activeProjectInMainScreen = getProjectById(activeProjectIdInMainScreen);
  const messageCenterProjectId = useSelector(getActiveProjectInMessageCenter);

  const isBetaFeaturesEnabledForUser = useSelector<RootReducerState, boolean>(
    (state) => getIsKeyEnabled(state, 'General_BetaFeaturesEnabled')
  );

  const isAiFeaturesEnabledForOffice = useSelector<RootReducerState, boolean>(
    (state) => getIsKeyEnabled(state, 'Cognitive_EnableAiFeatures')
  );

  const draftMessageOpen = useSelector<RootReducerState, boolean>((state) =>
    getBuddyIconVisibility(state)
  );

  const projectIdInWidgetarea = useSelector(getWidgetAreaActiveProjectId);
  const [messageId, setMessageId] = useState<string>(null);
  const [inMail, setInMail] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const match = location.pathname.match(/\/message\/([^/]+)\/details/);
    const newMessageId = match ? match[1] : null;
    setMessageId(newMessageId);
    const containsMail = location.pathname.includes('/mail/');
    setInMail(containsMail);
  }, [location]);

  useEffect(() => {
    if (
      selectedWidget === 'task' &&
      open &&
      !location.pathname.includes('/mail/')
    ) {
      dispatch(closeWidgetArea());
    }
  }, [location, selectedWidget, open, dispatch]);

  const [size, setSize] = useState<number>(panelDefaultWidth);

  const width = open ? size : iconBarWidth;
  const buddyIconVisibility =
    draftMessageOpen &&
    isBetaFeaturesEnabledForUser &&
    isAiFeaturesEnabledForOffice &&
    inMail;

  const isMyProjectInWidgetarea = useSelector(
    isMyProjectSelector(projectIdInWidgetarea)
  );

  const lastOpenFolderDriveItemIds = useSelector((state: RootReducerState) =>
    getLastOpenDriveItemFolder(state, projectIdInWidgetarea)
  );

  const projectInWidgetarea = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectIdInWidgetarea)
  );

  const isMe: boolean =
    (selectedProjectId ?? activeProjectIdInMainScreen) === 'me';
  const currentMessage = useSelector<RootReducerState, Message>((state) =>
    messageId ? getMessage(state, projectIdInWidgetarea, messageId) : null
  );

  const [emailConversationId, setEmailConversationId] = useState<string>(null);

  const comments = useSelector<RootReducerState, MailComment[]>(
    (state) => getCommentsByConversationId(state, emailConversationId) ?? []
  );
  const isFetching = useSelector<RootReducerState, boolean>((state) =>
    getIsFetchingComments(state)
  );

  const [newDriveItemDestinationId, setNewDriveItemDestinationId] =
    useState<DriveItemId>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const toggle = () => {
    if (open) {
      dispatch(closeWidgetArea());
    } else {
      dispatch(openWidgetArea());
    }
  };

  const resetSize = () => {
    setSize(panelDefaultWidth);
  };

  const openAreaAndSelectWidget = (widget: Widget) => {
    if (!open) {
      dispatch(openWidgetArea(widget));
    } else if (widget !== selectedWidget) {
      dispatch(selectWidget(widget));
    } else if (open && widget === selectedWidget) {
      dispatch(closeWidgetArea());
    }
  };

  const handleResizeStop: ResizeCallback = (e, direction, ref, delta) => {
    if (!open) {
      if (delta.width + iconBarWidth < panelDefaultWidth) {
        setSize(panelDefaultWidth);
      } else {
        setSize(delta.width + iconBarWidth);
      }
      dispatch(openWidgetArea());
    } else {
      setSize(delta.width + size);
    }
  };

  const setProjectId = useCallback(
    (projectId: ProjectId) => {
      dispatch(setActiveProjectWidgetBar(projectId));
    },
    [dispatch]
  );

  //#endregion

  //#region ------------------------------ components
  const icon: (
    iconName: IconName,
    widget: Widget,
    disabled?: boolean,
    style?: React.CSSProperties,
    showYellowDot?: boolean
  ) => React.ReactNode = (
    iconName,
    widget,
    disabled = false,
    style,
    showYellowDot
  ) => (
    <Tooltip title={t(`common:widgetArea.${widget}`)} placement="left">
      <div style={{ position: 'relative' }}>
        <Button
          onClick={openAreaAndSelectWidget.bind(this, widget)}
          type="link"
          size="large"
          iconProp={
            iconName === 'message-bot' ? ['far', iconName] : ['fal', iconName]
          }
          className={classNames(classes.iconButton, {
            [classes.iconButtonActive]: widget === selectedWidget,
          })}
          disabled={disabled}
          style={{ marginTop: '8px', width: '44px', ...style }}
        />
        {showYellowDot && <span className={classes.yellowDot}></span>}
      </div>
    </Tooltip>
  );

  const mailNotiz: (messageId: string) => React.ReactNode = (messageId) => (
    <MailComments
      sharedMailboxId={activeProjectInMainScreen?.sharedMailboxUserId}
      messageId={messageId}
      comments={comments}
      isFetching={isFetching}
      emailConversationId={emailConversationId}
      setEmailConversationId={setEmailConversationId}
      projectId={activeProjectIdInMainScreen}
    />
  );

  const prioBuddy: (messageId: string) => React.ReactNode = (messageId) => (
    <PrioBuddy
      messageId={messageId}
      projectId={
        messageCenterProjectId
          ? messageCenterProjectId
          : activeProjectIdInMainScreen
      }
    />
  );

  const documentWidget = (
    <DocumentsWidget
      projectId={projectIdInWidgetarea}
      className={classes.documents}
      size={size}
      initialDriveItemId={
        newDriveItemDestinationId ?? lastOpenFolderDriveItemIds?.documentsWidget
      }
      onProjectIdChange={setProjectId}
      setNewDriveItemDestinationId={setNewDriveItemDestinationId}
      activeProjectIdInDocumentsTable={activeProjectIdInMainScreen}
    />
  );

  //#endregion

  let widget: React.ReactNode = <></>;

  switch (selectedWidget) {
    case 'mail': {
      if (!!activeProjectIdInMainScreen && !isMe) {
        widget = !disablePersonalMessages ? (
          <WidgetMailSelectionList projectId={activeProjectIdInMainScreen} />
        ) : null;
      }
      break;
    }
    case 'planner': {
      widget = !!activeProjectInMainScreen?.masterPlanId && (
        <TasksWidget projectId={activeProjectIdInMainScreen} />
      );
      break;
    }
    case 'documents': {
      widget = documentWidget;
      break;
    }
    case 'uploadManager': {
      widget = (
        <UploadManager
          projectId={activeProjectIdInMainScreen}
          onProjectIdChange={setProjectId}
          onParentDriveItemClick={(driveItemId) => {
            dispatch(
              updateDriveItemPath(activeProjectIdInMainScreen, {
                documentsWidget: driveItemId,
              })
            );
          }}
        />
      );
      break;
    }
    case 'task': {
      widget = (
        <MailTaskList
          projectId={activeProjectIdInMainScreen}
          messageId={messageId}
        />
      );
      break;
    }
    case 'comment': {
      if (activeProjectInMainScreen && messageId) {
        widget = mailNotiz(messageId);
      } else {
        widget = documentWidget;
        dispatch(selectWidget('documents'));
      }
      break;
    }
    case 'prioBuddy': {
      if (activeProjectInMainScreen && buddyIconVisibility) {
        widget = prioBuddy(messageId);
      } else {
        widget = documentWidget;
        dispatch(closeWidgetArea('documents'));
      }
      break;
    }
  }

  //#region ------------------------------ Effects
  useEffect(() => {
    if (selectedWidget === 'mail' && disablePersonalMessages) {
      dispatch(closeWidgetArea(null));
    }
  }, [selectedWidget, disablePersonalMessages, dispatch]);

  useEffect(() => {
    if (!isMyProjectInWidgetarea && selectedWidget !== 'documents') {
      dispatch(closeWidgetArea());
    }
  }, [isMyProjectInWidgetarea, selectedWidget, dispatch]);

  useEffect(() => {
    if (selectedProjectId) {
      setProjectId(selectedProjectId);
    } else if (activeProjectIdInMainScreen !== 'me' && !projectIdInWidgetarea) {
      setProjectId(activeProjectIdInMainScreen);
    } else if (messageCenterProjectId && messageCenterProjectId !== 'me') {
      setProjectId(messageCenterProjectId);
    }
  }, [
    selectedProjectId,
    activeProjectIdInMainScreen,
    projectIdInWidgetarea,
    messageCenterProjectId,
    setProjectId,
  ]);

  useEffect(() => {
    if (open && selectedWidget === 'mail' && (isMe || !projectIdInWidgetarea)) {
      dispatch(closeWidgetArea('documents'));
    }
  }, [open, isMe, projectIdInWidgetarea, selectedWidget, dispatch]);

  useEffect(() => {
    if (emailConversationId) {
      dispatch(fetchConversationComments(emailConversationId));
    }
  }, [dispatch, emailConversationId]);

  useEffect(() => {
    setEmailConversationId(
      currentMessage?.customSingleValueExtendedProperties?.emailConversationId
    );
  }, [currentMessage]);

  useEffect(() => {
    if (selectedWidget === 'comment' && !messageId) {
      dispatch(closeWidgetArea(null));
    }
  }, [selectedWidget, messageId, dispatch]);
  //#endregion

  return (
    <>
      <div className={classes.hiddenElement} style={{ width: width }} />
      <WidgetAreaResizable
        onResizeStop={handleResizeStop}
        size={{ width: size, height: '100%' }}
        minWidth={panelDefaultWidth}
        maxWidth={panelMaxWidth}
        closedMinWidth={iconBarWidth}
        open={open}
      />
      <div
        className={classes.root}
        style={{ width: open ? size : iconBarWidth }}
      >
        <div className={classes.iconBar}>
          {!disablePersonalMessages &&
            !isMe &&
            icon('inbox', 'mail', !isMyProjectInWidgetarea)}
          {icon('folder-tree', 'documents')}
          <WidgetBarUploadManagerNavigationButton
            onClick={() => openAreaAndSelectWidget('uploadManager')}
            isActive={selectedWidget === 'uploadManager'}
            widgetBarIsOpen={open}
            className={
              selectedWidget === 'uploadManager' && classes.iconButtonActive
            }
          />
          {!!projectInWidgetarea?.masterPlanId &&
            icon('check', 'planner', !isMyProjectInWidgetarea)}
          {projectIdInWidgetarea && (
            <>
              <div className={classes.divider}></div>
              {!isMe && messageId && (
                <>
                  {icon(
                    'comments',
                    'comment',
                    false,
                    { marginTop: '0px' },
                    !!emailConversationId
                  )}
                  {icon(
                    'list-check',
                    'task',
                    false,
                    { marginTop: '0px' },
                    !!currentMessage?.customSingleValueExtendedProperties
                      .messageTaskRelationId
                  )}
                </>
              )}

              {buddyIconVisibility &&
                icon(
                  'message-bot',
                  'prioBuddy',
                  false,
                  { marginTop: '0px' },
                  false
                )}
            </>
          )}
          <Flex.Item flex={1} />
          {size > panelDefaultWidth && open && (
            <Button
              type="link"
              onClick={resetSize}
              iconProp={['fal', 'chevron-right']}
              className={classes.toggleButton}
            />
          )}
          <Button
            type="link"
            onClick={toggle}
            iconProp={[
              'fal',
              open ? 'chevron-double-right' : 'chevron-double-left',
            ]}
            className={classes.toggleButton}
          />
        </div>
        {open && (
          <div
            className={classes.panelChild}
            style={{ width: size - iconBarWidth - 1 }}
          >
            {isMyProjectInWidgetarea ? widget : null}
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetArea;
